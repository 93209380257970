@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;900&display=swap");

.scroll-button {
    background: none;
    border: none;
    color: black;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 3rem;
    font-weight: 700;
  }

  .scroll-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Adjust the margin according to your needs */
    position: relative;
  }
// Media Qurey Manager
/*
0 - 600px:     Phone
600 - 900px:   Tablet Potrait
900 - 1200px:  Tablet Landscape
[1200 - 1800]  is where our normal style applies
1800px + :     Big desktop

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/
@mixin respond($breakpoint) {
  @if ($breakpoint ==phone) {
    @media (max-width: 37.5em) {
      @content
    }

    ; //600px
  }

  @if ($breakpoint ==tab-port) {
    @media (max-width: 56.25em) {
      @content
    }

    ; //900px
  }

  @if ($breakpoint ==tab-land) {
    @media (max-width: 75em) {
      @content
    }

    ; //1200px
  }

  @if ($breakpoint ==big-desktop) {
    @media (min-width: 112.5em) {
      @content
    }

    ; //1800px
  }

  @if ($breakpoint ==w300) {
    @media (max-width: 32em) {
      @content
    }

    ;
  }

  @if ($breakpoint ==w320) {
    @media (max-width: 35em) {
      @content
    }

    ;
  }

  @if ($breakpoint ==w600) {
    @media (max-width: 37.5em) {
      @content
    }

    ;
  }

  @if ($breakpoint ==w600) {
    @media (max-width: 37.5em) {
      @content
    }

    ;
  }

  @if ($breakpoint ==w768) {
    @media (max-width: 48em) {
      @content
    }

    ;
  }

  @if ($breakpoint ==w1024) {
    @media (max-width: 64em) {
      @content
    }

    ;
  }

  @if ($breakpoint ==w1200) {
    @media (max-width: 75em) {
      @content
    }

    ;
  }

  @if ($breakpoint ==w1440) {
    @media (max-width: 90em) {
      @content
    }

    ;
  }

  @if ($breakpoint ==w1600) {
    @media (max-width: 100em) {
      @content
    }

    ;
  }
}
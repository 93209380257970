@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;900&display=swap");

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    border-radius: 5px;
}

div.home .bm-burger-bars {
  background: #000000;
}

div.about .bm-burger-bars {
    background: #ffffff;
}

div.usecases .bm-burger-bars {
    background: #ffffff;
}

div.blog .bm-burger-bars {
    background: #ffffff;
}

div.contact .bm-burger-bars {
    background: #ffffff;
}

div.operations .bm-burger-bars {
  background: #000000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #4a4a4a;
  padding: 2.5em 1.5em 0;
  font-size: 2.15em;
  font-family: "Montserrat", sans-serif;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #4a4a4a;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The following is optional and it's for changing the scrollbar styles */
.bm-item-list::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.bm-item-list::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 5px;
}



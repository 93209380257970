@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/variables";

@import "base/base";

@import "components/components";


.container {
    width: 100%;
    // height: 100vh;
    background-color: $color-white;
    position: relative;
    overflow: hidden;

    @include respond(w600) {}
}

.main-nav {
    width: 100%;
    position: absolute;
    top: 5rem;
    z-index: 200;

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 128rem;
        height: 10rem;
        margin: auto;
        position: relative;
        z-index: 50;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 0 4rem;
        }
    }

    &__logo {
        width: 10.6rem;
        height: auto;
    }

    &__menu {
        background-color: #181818;
        position: fixed;
        top: 3.6rem;
        right: 4.6rem;
        left: 4.6rem;
        z-index: 250;
        padding: 2.8rem 2.6rem;
        border-radius: 3.8rem;

        @include respond(w600) {
            top: 2rem;
            right: 2rem;
            left: 2rem;
        }

        &--header {
            display: flex;
            align-items: center;

            .menu-logo {
                width: 9.3rem;
            }

            .menu-title {
                font-size: 4.5rem;
                font-weight: 700;
                color: #ffffff;
                margin: 0 0 0 5.2rem;
                flex-grow: 1;
            }

            .menu-email {
                font-family: "Jura", sans-serif;
                font-size: 2rem;
                font-weight: 600;
                line-height: 2.4rem;
                color: #ffffff;
                text-decoration: none;
                transition: .5s;
                margin-right: 6.7rem;

                &:hover {
                    opacity: .6;
                }

                @include respond(w600) {
                    margin-right: 0;
                }
            }
        }

        &--body {
            display: flex;
            justify-content: space-between;
            padding: 4rem 7.2rem 0 14.8rem;

            @include respond(w600) {
                flex-direction: column;
            }

            .item {
                .item-title {
                    font-size: 3rem;
                    font-weight: 700;
                    line-height: 3.2rem;
                    color: #ffffff;
                    margin-top: 0;
                    margin-bottom: .9rem;
                }

                .list {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 4.5rem;

                    &--item {
                        font-family: "Jura", sans-serif;
                        font-size: 1.6rem;
                        font-weight: 600;
                        line-height: 1.9rem;
                        color: #ffffff;
                        text-decoration: none;
                        transition: .5s;

                        &:hover {
                            opacity: .6;
                        }

                        &+.list--item {
                            margin-top: .8rem;
                        }
                    }
                }

                .grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    row-gap: 1.44rem;
                    column-gap: 1.8rem;

                    .btn {
                        font-family: "Jura", sans-serif;
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: #000000;
                        background-color: #EAC44B;
                        width: 14.6rem;
                        height: 3.8rem;
                        display: flex;
                        align-items: center;
                        padding: 0 1.6rem;
                        border-radius: .5rem;
                        border: none;
                        margin: 0;
                        text-decoration: none;
                        transition: .5s;
                        cursor: pointer;

                        &:hover {
                            background-color: darken(#EAC44B, 10%);
                        }
                    }
                }
            }
        }

        @include respond(w600) {}
    }

    &__menu-bg {
        background-color: rgba(0, 0, 0, 0.36);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 245;
    }

    &__icon-btn {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        transition: .5s;
        cursor: pointer;

        img {
            width: 2.55rem;
            height: auto;

            @include respond(w600) {
                filter: invert(1);
            }
        }

        &:hover {
            opacity: .8;
        }
    }

    &__btn {
        background: none;
        border: .1rem solid $color-primary;
        border-radius: 1.2rem 0 1.2rem 0;
        height: 5.6rem;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        color: $color-primary;
        padding: 0 3.1rem;
        align-items: center;
        justify-content: center;
        transition: .5s;
        cursor: pointer;

        &:hover {
            background-color: rgba($color-primary, .25);
            // color: $color-black;
        }

        &+.main-nav__btn {
            margin-left: 1.6rem;
        }

        &.menu {
            padding: 0 1.4rem;
            margin-left: 1.86rem;
        }
    }
}

.header {
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    position: relative;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond(w1440) {}

    @include respond(w1200) {}

    @include respond(w1024) {}

    @include respond(w768) {}

    @include respond(w600) {}

    &__logo {
        width: 33.5rem;
        height: auto;
    }


    &__menu {
        position: absolute;
        top: 4rem;
        right: 7.5rem;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        transition: .5s;
        cursor: pointer;

        @include respond(w600) {
            top: 3.2rem;
            right: 3.2rem;
        }

        img {
            width: 4.5rem;
            height: auto;
        }

        &:hover {
            opacity: .6;
        }
    }
}

.about {
    width: 100%;
    position: relative;
    background-color: $color-primary;

    &__pic {
        width: 100%;
        height: 41.6rem;
        object-fit: cover;
    }

    &__container {
        width: 100%;
        max-width: 106rem;
        padding: 4rem 0 5rem;
        margin: auto;
        position: relative;
        z-index: 50;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 4rem;
        }
    }

    &__title {
        font-size: 3.8rem;
        line-height: 3.9rem;
        color: $color-black;
        font-weight: 400;
        text-align: center;
        margin: auto;
        max-width: 70rem;
    }

    &__details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 3.5rem;
        margin-top: 5rem;

        @include respond(w600) {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 4rem;
        }

        &--item {
            font-size: 1.9rem;
            line-height: 2.2rem;
            color: $color-black;
            font-weight: 400;
            text-align: justify;
        }
    }
}

.features {
    width: 100%;
    position: relative;
    background-color: $color-primary;

    &__container {
        width: 100%;
        max-width: 106rem;
        padding-top: 4rem;
        padding-bottom: 4rem;
        margin: auto;
        position: relative;
        z-index: 50;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 4rem;
        }
    }

    &__title {
        font-size: 3.8rem;
        line-height: 3.9rem;
        color: $color-black;
        font-weight: 400;
        text-align: center;
        margin: auto;

        strong {
            font-weight: 700;
        }
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4rem 0 3rem;

        &--item {
            display: flex;
            flex-direction: column;
            align-items: center;

            &+.features__list--item {
                margin-left: 5.6rem;
            }

            .icon {
                width: 8.6rem;
                height: 8.6rem;
                border-radius: 4.3rem;
                background-color: #808080;
                border: .8rem solid #4d4d4d;
                display: flex;
                align-items: center;
                justify-content: center;

                .express-icon {
                    width: auto;
                    height: 4.5rem;
                }

                .ltl-icon {
                    width: auto;
                    height: 4.2rem;
                }

                .b2b-icon {
                    width: auto;
                    height: 5rem;
                }
            }

            .label {
                font-size: 1.3rem;
                font-weight: 500;
                color: $color-black;
                line-height: 1;
                margin-top: 1rem;
            }
        }
    }

    &__pic {
        width: 100%;
        height: auto;
    }
}

.operations {
    width: 100%;
    position: relative;
    background-color: $color-primary;

    &__container {
        width: 100%;
        max-width: 106rem;
        padding: 5.5rem 0;
        margin: auto;
        position: relative;
        z-index: 50;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {}
    }

    &__title {
        font-size: 3.8rem;
        line-height: 3.9rem;
        color: $color-black;
        font-weight: 400;
        text-align: center;
        margin: auto;
    }

    &__details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 3.5rem;
        margin-top: 5rem;

        @include respond(w600) {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 4rem;
            margin: 4rem;
        }

        &--item {
            font-size: 1.9rem;
            line-height: 2.2rem;
            color: $color-black;
            font-weight: 400;
            text-align: justify;
        }
    }

    &__pic {
        background-color: #808080;
        width: 100%;
        padding: 4.5rem 0 2rem;
        margin: auto;
        position: relative;
        z-index: 50;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {}

        .pic {
            width: 100%;
            max-width: 106rem;
        }

        .btn {
            background-color: $color-primary;
            height: 5.4rem;
            border-radius: 1.2rem;
            border: .5rem solid $color-black;
            font-family: 'Montserrat', sans-serif;
            font-size: 1.8rem;
            font-weight: 700;
            color: $color-white;
            margin: 6rem 0 0;
            padding: 0 1.6rem;
            cursor: pointer;
            transition: .5s;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: darken($color-primary, 10%);
            }
        }
    }
}

.main-footer {
    background-color: #4a4a4a;
    width: 100%;
    position: relative;
    padding: 3.4rem 0;

    &__container {
        width: 100%;
        max-width: 106rem;
        margin: auto;
        position: relative;
        z-index: 50;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {}
    }

    &__logo {
        width: 10rem;
    }

    &__links {
        display: flex;
        padding-top: 3.5rem;
        padding-bottom: 3rem;

        @include respond(w600) {}

        &--item {
            font-size: 1.8rem;
            font-weight: 600;
            color: #ffffff;
            line-height: 1;
            cursor: pointer;
            transition: .5s;
            text-decoration: none;
            background: none;
            border: none;
            padding: 0;
            margin: 0;

            &:hover {
                opacity: .6;
            }

            &+.main-footer__links--item {
                margin-left: 3.6rem;
            }
        }
    }

    &__copy {
        font-size: 1.4rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 1;
    }
}

.operations-header {
    width: 100%;
    height: 90rem;
    background-color: $color-white;
    position: relative;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond(w1440) {}

    @include respond(w1200) {}

    @include respond(w1024) {}

    @include respond(w768) {}

    @include respond(w600) {}

    &__title {
        font-size: 3.8rem;
        line-height: 3.9rem;
        color: #4A4A4A;
        font-weight: 400;
        text-align: center;
        margin: auto;
        position: absolute;
        top: 4rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;

        @include respond(w600) {
            top: 7rem;
        }
    }

    &__desc {
        font-size: 1.3rem;
        line-height: 1.8rem;
        color: #000000;
        font-weight: 300;
        text-align: center;
        margin: auto;
        max-width: 50rem;
        position: absolute;
        top: 9rem;
        left: 50%;
        transform: translateX(-50%);

        @include respond(w600) {
            top: 12rem;
            max-width: 100%;
        }
    }

    &__menu {
        position: absolute;
        z-index: 100;
        top: 4rem;
        right: 7.5rem;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        transition: .5s;
        cursor: pointer;

        @include respond(w600) {
            top: 3.2rem;
            right: 3.2rem;
        }

        img {
            width: 4.5rem;
            height: auto;
        }

        &:hover {
            opacity: .6;
        }
    }
}

.bi-modal-solution {
    width: 100%;
    position: relative;
    background-color: #808080;
    padding-top: 5rem;
    padding-bottom: 9rem;



    &__title {
        font-size: 3.8rem;
        line-height: 3.9rem;
        color: $color-white;
        font-weight: 400;
        text-align: center;
        margin: auto;
        margin-bottom: 5rem;

        @include respond(w600) {
            padding: 0 4rem;
        }
    }

    &__pic {
        width: 100%;
        height: auto;
    }
}

.description {
    width: 100%;
    position: relative;

    &__bg {
        width: 100%;
    }

    &__container {
        background-color: #4a4a4a;
        width: 100%;
        height: 17rem;
        margin: auto;
        position: relative;
        z-index: 50;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            height: 25rem;
        }
    }

    &__list {
        position: absolute;
        top: 7.5rem;
        width: 100%;
        max-width: 106rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;

        @include respond(w600) {
            transform: translateX(-50%) scale(.9);
        }

        &--item {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            flex-basis: 1rem;

            .num {
                width: 20.5rem;
                height: 20.5rem;
                border-radius: 11rem;
                background-color: #4a4a4a;
                border: 1.2rem solid $color-primary;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .value {
                    font-size: 7rem;
                    color: $color-white;
                    font-weight: 700;
                    text-align: center;
                }

                .label {
                    font-size: 2rem;
                    color: $color-white;
                    font-weight: 400;
                    text-align: center;
                }
            }

            .title {
                font-size: 1.6rem;
                color: $color-black;
                font-weight: 700;
                margin-top: 2rem;
                text-align: center;
            }

            .desc {
                font-size: 1.6rem;
                color: $color-black;
                font-weight: 400;
                text-align: center;
            }
        }
    }
}

.about-header {
    width: 100%;
    padding-top: 13rem;
    padding-bottom: 5rem;
    background-color: #4a4a4a;
    position: relative;

    &__container {
        width: 100%;
        max-width: 106rem;
        margin: auto;
        position: relative;
        z-index: 50;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 0 4rem;
        }
    }

    &__menu {
        position: absolute;
        top: 4rem;
        right: 7.5rem;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        transition: .5s;
        cursor: pointer;

        @include respond(w600) {
            top: 3.2rem;
            right: 3.2rem;
        }

        img {
            width: 4.5rem;
            height: auto;
        }

        &:hover {
            opacity: .6;
        }
    }

    &__title {
        font-size: 4rem;
        font-weight: 700;
        color: #ebebeb;
        text-align: center;
    }

    &__desc {
        font-size: 1.8rem;
        font-weight: 300;
        color: #ebebeb;
        text-align: justify;
        margin-top: 4rem;
    }
}

.about-content {
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background-color: $color-primary;
    position: relative;

    &__container {
        width: 100%;
        max-width: 106rem;
        margin: auto;
        position: relative;
        z-index: 50;
        display: flex;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 4rem;
            flex-direction: column;
        }
    }

    &__col {
        flex-grow: 1;
        flex-basis: 1rem;

        &:first-child {
            padding-right: 4rem;

            @include respond(w600) {
                padding-bottom: 4rem;
                padding-right: 0;
            }
        }

        &:last-child {
            padding-left: 4rem;

            @include respond(w600) {
                padding-top: 4rem;
                padding-left: 0;
            }
        }

        &+.about-content__col {
            border-left: .2rem solid #808080;

            @include respond(w600) {
                border-top: .2rem solid #808080;
                border-left: none;
            }
        }

        .title {
            font-size: 1.8rem;
            font-weight: 500;
            color: $color-black;
            margin-bottom: .4rem;
        }

        .desc {
            font-size: 1.8rem;
            font-weight: 300;
            color: $color-black;
            margin-bottom: .6rem;
            margin-left: 1.2rem;
        }
    }
}

.usecases-header {
    width: 100%;
    padding-top: 13rem;
    padding-bottom: 5rem;
    background-color: #4a4a4a;
    position: relative;

    &__container {
        width: 100%;
        max-width: 106rem;
        margin: auto;
        position: relative;
        z-index: 50;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {}
    }

    &__menu {
        position: absolute;
        top: 4rem;
        right: 7.5rem;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        transition: .5s;
        cursor: pointer;

        @include respond(w600) {}

        img {
            width: 4.5rem;
            height: auto;
        }

        &:hover {
            opacity: .6;
        }
    }

    &__title {
        font-size: 4rem;
        font-weight: 700;
        color: #ebebeb;
        text-align: center;
    }
}

.usecases-content {
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background-color: #808080;
    position: relative;

    &__container {
        width: 100%;
        max-width: 106rem;
        margin: auto;
        position: relative;
        z-index: 50;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {}
    }

    &__item {
        padding: 1.5rem;
        display: flex;

        @include respond(w600) {
            padding: 4rem;
            flex-direction: column;
            align-items: center;
        }

        &--img {
            width: 24rem;
            height: auto;
        }

        &--title {
            font-size: 1.8rem;
            font-weight: 600;
            color: $color-black;
            padding-left: 2.4rem;
            width: 19rem;

            @include respond(w600) {
                padding: 3.2rem 0;
            }
        }

        &--details {
            flex-grow: 1;
        }

        &--desc {
            font-size: 1.8rem;
            font-weight: 300;
            color: $color-white;

            &+.usecases-content__item--desc {
                margin-top: 2rem;
            }
        }

        &+.usecases-content__item {
            border-top: .2rem solid #4a4a4a;
        }
    }
}

.blog-header {
    width: 100%;
    padding-top: 13rem;
    padding-bottom: 5rem;
    background-color: #4a4a4a;
    position: relative;

    &__container {
        width: 100%;
        max-width: 106rem;
        margin: auto;
        position: relative;
        z-index: 50;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {}
    }

    &__menu {
        position: absolute;
        top: 4rem;
        right: 7.5rem;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        transition: .5s;
        cursor: pointer;

        @include respond(w600) {
            top: 3.2rem;
            right: 3.2rem;
        }

        img {
            width: 4.5rem;
            height: auto;
        }

        &:hover {
            opacity: .6;
        }
    }

    &__title {
        font-size: 4rem;
        font-weight: 700;
        color: #ebebeb;
        text-align: center;
    }
}

.blog-content {
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.6rem;
    background-color: $color-white;
    position: relative;

    &__container {
        width: 100%;
        max-width: 106rem;
        margin: auto;
        position: relative;
        z-index: 50;

        @include respond(w1440) {}

        @include respond(w1200) {}

        @include respond(w768) {}

        @include respond(w600) {
            padding: 2rem;
        }
    }

    &__item {
        background-color: #f4f3f3;
        padding: 1rem;
        display: flex;
        border-radius: 1.2rem;

        &--img {
            width: 25.6rem;
            height: 14rem;
            border: .6rem solid #ffffff;
            border-radius: .4rem;
            object-fit: cover;

            @include respond(w600) {
                height: auto;
            }
        }

        &--body {
            flex-grow: 1;
            padding: 0 3.6rem;
            display: flex;
            flex-direction: column;

            .date {
                font-size: 1.8rem;
                font-weight: 300;
                color: $color-black;
                align-self: flex-end;
            }

            .title {
                font-size: 1.8rem;
                font-weight: 500;
                color: $color-black;
                margin-top: .6rem;
                margin-bottom: .6rem;
            }

            .desc {
                font-size: 1.8rem;
                font-weight: 300;
                color: $color-black;
                margin-bottom: .6rem;
            }

            .btn {
                font-size: 1.8rem;
                font-weight: 300;
                color: $color-black;
                font-style: italic;
                background: none;
                border: none;
                padding: 0;
                margin: 0;
                cursor: pointer;
                transition: .5s;
                align-self: flex-end;

                &:hover {
                    opacity: .6;
                }
            }
        }

        &+.blog-content__item {
            margin-top: .8rem;
        }
    }
}
/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
  }

  input[type=email], select, textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
  }
  
  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* When moving the mouse over the submit button, add a darker green color */
  input[type=submit]:hover {
    background-color: #45a049;
  }

  input {
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
  }

  textarea {
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
  }
  
  /* Add a background color and some padding around the form */
  .contact-btn {
    background-color: #facf2f;
    height: 5.4rem;
    border-radius: 1.2rem;
    border: 0.5rem solid #000000;
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    color: #ffffff;
    margin: 1rem 0 0;
    padding: 0 1.6rem;
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.contact-btn:hover {
    background-color: #f0bf06;
  }
/*
0 - 600px:     Phone
600 - 900px:   Tablet Potrait
900 - 1200px:  Tablet Landscape
[1200 - 1800]  is where our normal style applies
1800px + :     Big desktop

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;900&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}
@media (max-width: 90em) {
  html {
    font-size: 56%;
  }
}
@media (max-width: 75em) {
  html {
    font-size: 45%;
  }
}
@media (max-width: 48em) {
  html {
    font-size: 37%;
  }
}
@media (max-width: 37.5em) {
  html {
    font-size: 37.5%;
  }
}
@media (max-width: 37.5em) {
  html {
    font-size: 37.5%;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #000000;
  line-height: normal;
  box-sizing: border-box;
  overflow-x: hidden;
}

.yellow-btn {
  background-color: #EAC44B;
  height: 5.1rem;
  padding: 0 2.4rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  color: #0F0F0F;
  font-weight: 700;
  border: none;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;
}
.yellow-btn:hover {
  background-color: #e5b51d;
}

.yellow-outline-btn {
  background-color: rgba(234, 196, 75, 0);
  height: 4.8rem;
  padding: 0 2.6rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  color: #EAC44B;
  font-weight: 600;
  border: 0.1rem solid #EAC44B;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;
}
.yellow-outline-btn:hover {
  background-color: rgba(234, 196, 75, 0.25);
}

.d-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-items-center {
  align-items: center;
}

.container {
  width: 100%;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}
.main-nav {
  width: 100%;
  position: absolute;
  top: 5rem;
  z-index: 200;
}
.main-nav__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 128rem;
  height: 10rem;
  margin: auto;
  position: relative;
  z-index: 50;
}
@media (max-width: 37.5em) {
  .main-nav__container {
    padding: 0 4rem;
  }
}
@media (max-width: 37.5em) {
  .main-nav__container {
    padding: 0 4rem;
  }
}
.main-nav__logo {
  width: 10.6rem;
  height: auto;
}
.main-nav__menu {
  background-color: #181818;
  position: fixed;
  top: 3.6rem;
  right: 4.6rem;
  left: 4.6rem;
  z-index: 250;
  padding: 2.8rem 2.6rem;
  border-radius: 3.8rem;
}
@media (max-width: 37.5em) {
  .main-nav__menu {
    top: 2rem;
    right: 2rem;
    left: 2rem;
  }
}
@media (max-width: 37.5em) {
  .main-nav__menu {
    top: 2rem;
    right: 2rem;
    left: 2rem;
  }
}
.main-nav__menu--header {
  display: flex;
  align-items: center;
}
.main-nav__menu--header .menu-logo {
  width: 9.3rem;
}
.main-nav__menu--header .menu-title {
  font-size: 4.5rem;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 0 5.2rem;
  flex-grow: 1;
}
.main-nav__menu--header .menu-email {
  font-family: "Jura", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: #ffffff;
  text-decoration: none;
  transition: 0.5s;
  margin-right: 6.7rem;
}
.main-nav__menu--header .menu-email:hover {
  opacity: 0.6;
}
@media (max-width: 37.5em) {
  .main-nav__menu--header .menu-email {
    margin-right: 0;
  }
}
@media (max-width: 37.5em) {
  .main-nav__menu--header .menu-email {
    margin-right: 0;
  }
}
.main-nav__menu--body {
  display: flex;
  justify-content: space-between;
  padding: 4rem 7.2rem 0 14.8rem;
}
@media (max-width: 37.5em) {
  .main-nav__menu--body {
    flex-direction: column;
  }
}
@media (max-width: 37.5em) {
  .main-nav__menu--body {
    flex-direction: column;
  }
}
.main-nav__menu--body .item .item-title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.2rem;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0.9rem;
}
.main-nav__menu--body .item .list {
  display: flex;
  flex-direction: column;
  margin-bottom: 4.5rem;
}
.main-nav__menu--body .item .list--item {
  font-family: "Jura", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.9rem;
  color: #ffffff;
  text-decoration: none;
  transition: 0.5s;
}
.main-nav__menu--body .item .list--item:hover {
  opacity: 0.6;
}
.main-nav__menu--body .item .list--item + .list--item {
  margin-top: 0.8rem;
}
.main-nav__menu--body .item .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.44rem;
  column-gap: 1.8rem;
}
.main-nav__menu--body .item .grid .btn {
  font-family: "Jura", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  color: #000000;
  background-color: #EAC44B;
  width: 14.6rem;
  height: 3.8rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
  border-radius: 0.5rem;
  border: none;
  margin: 0;
  text-decoration: none;
  transition: 0.5s;
  cursor: pointer;
}
.main-nav__menu--body .item .grid .btn:hover {
  background-color: #e5b51d;
}
.main-nav__menu-bg {
  background-color: rgba(0, 0, 0, 0.36);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 245;
}
.main-nav__icon-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: 0.5s;
  cursor: pointer;
}
.main-nav__icon-btn img {
  width: 2.55rem;
  height: auto;
}
@media (max-width: 37.5em) {
  .main-nav__icon-btn img {
    filter: invert(1);
  }
}
@media (max-width: 37.5em) {
  .main-nav__icon-btn img {
    filter: invert(1);
  }
}
.main-nav__icon-btn:hover {
  opacity: 0.8;
}
.main-nav__btn {
  background: none;
  border: 0.1rem solid #facf2f;
  border-radius: 1.2rem 0 1.2rem 0;
  height: 5.6rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  color: #facf2f;
  padding: 0 3.1rem;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}
.main-nav__btn:hover {
  background-color: rgba(250, 207, 47, 0.25);
}
.main-nav__btn + .main-nav__btn {
  margin-left: 1.6rem;
}
.main-nav__btn.menu {
  padding: 0 1.4rem;
  margin-left: 1.86rem;
}

.header {
  width: 100%;
  height: 100vh;
  background-color: #facf2f;
  position: relative;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.header__logo {
  width: auto;
  height: 60vh;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
}
.header__menu {
  position: absolute;
  top: 4rem;
  right: 7.5rem;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: 0.5s;
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .header__menu {
    top: 3.2rem;
    right: 3.2rem;
  }
}
@media (max-width: 37.5em) {
  .header__menu {
    top: 3.2rem;
    right: 3.2rem;
  }
}
.header__menu img {
  width: 4.5rem;
  height: auto;
}
.header__menu:hover {
  opacity: 0.6;
}

.about {
  width: 100%;
  position: relative;
  background-color: #facf2f;
}

@supports (-webkit-touch-callout: none) {
  .about__pic {
    width: 100%;
    height: 41.6rem;
    background-image: url('../../public/The-city-2560x1600.avif');
    background-position: center;
  }
}

@supports not (-webkit-touch-callout: none) {
  .about__pic {
    width: 100%;
    height: 41.6rem;
    background-image: url('../../public/The-city-2560x1600.avif');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
}

.cloud__pic {
  width: 100%;
  height: 60rem;
  position: relative;
  background-image: url('../../public/slice1.avif');
  background-attachment: fixed;
  background-position: center;
  z-index: 50;
}
.drone__pic {
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: -40%;
  z-index: 51;
}

@media only screen and (max-width: 375px) {
  .cloud__pic {
    width: 100%;
    height: 60rem;
    background-image: url('../../public/slice1.avif');
    background-position: center;
    z-index: 50;
  }
  .drone__pic {
    width: 100%;
    height: auto;
    margin-bottom: -40%;
    z-index: 51;
  }
}

@media only screen and (min-width: 375px) {
  .cloud__pic {
    width: 100%;
    height: 60rem;
    position: relative;
    background-image: url('../../public/slice1.avif');
    background-attachment: fixed;
    background-position: center;
    z-index: 50;
  }
  .drone__pic {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: -40%;
    z-index: 51;
  }
}

@media screen and (max-width: 40em) {
  .cloud__pic {
    height: 25rem;
  }
}

@media screen and (min-width: 1900px) {
  .drone__pic {
    width: 50%;
    margin-bottom: -20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-position: center;
  }

  .cloud__pic {
    height: 60rem;
  }
}

.about__container {
  width: 100%;
  max-width: 106rem;
  padding: 4rem 0 5rem;
  margin: auto;
  position: relative;
  z-index: 50;
}
@media (max-width: 37.5em) {
  .about__container {
    padding: 4rem;
  }
}
@media (max-width: 37.5em) {
  .about__container {
    padding: 4rem;
  }
}
.about__title {
  font-size: 3.8rem;
  line-height: 3.9rem;
  color: #000000;
  font-weight: 400;
  text-align: center;
  margin: auto;
  max-width: 70rem;
}
.about__details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3.5rem;
  margin-top: 5rem;
}
@media (max-width: 37.5em) {
  .about__details {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 4rem;
  }
}
@media (max-width: 37.5em) {
  .about__details {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 4rem;
  }
}
.about__details--item {
  font-size: 1.9rem;
  line-height: 2.2rem;
  color: #000000;
  font-weight: 400;
  text-align: justify;
}

.features {
  width: 100%;
  position: relative;
  background-color: #facf2f;
}
.features__container {
  width: 100%;
  max-width: 106rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin: auto;
  position: relative;
  z-index: 50;
}
@media (max-width: 37.5em) {
  .features__container {
    padding: 4rem;
  }
}
@media (max-width: 37.5em) {
  .features__container {
    padding: 4rem;
  }
}
.features__title {
  font-size: 3.8rem;
  line-height: 3.9rem;
  color: #000000;
  font-weight: 400;
  text-align: center;
  margin: auto;
}
.features__title strong {
  font-weight: 700;
}
.features__list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0 3rem;
}
.features__list--item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.features__list--item + .features__list--item {
  margin-left: 5.6rem;
}
.features__list--item .icon {
  width: 8.6rem;
  height: 8.6rem;
  border-radius: 4.3rem;
  background-color: #808080;
  border: 0.8rem solid #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.features__list--item .icon .express-icon {
  width: auto;
  height: 4.5rem;
}
.features__list--item .icon .ltl-icon {
  width: auto;
  height: 4.2rem;
}
.features__list--item .icon .b2b-icon {
  width: auto;
  height: 5rem;
}
.features__list--item .label {
  font-size: 1.3rem;
  font-weight: 500;
  color: #000000;
  line-height: 1;
  margin-top: 1rem;
}

.operations {
  width: 100%;
  position: relative;
  background-color: #facf2f;
}
.operations__container {
  width: 100%;
  max-width: 106rem;
  padding: 5.5rem 0;
  margin: auto;
  position: relative;
  z-index: 50;
}
.operations__title {
  font-size: 3.8rem;
  line-height: 3.9rem;
  color: #000000;
  font-weight: 400;
  text-align: center;
  margin: auto;
}
.operations__details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3.5rem;
  margin-top: 5rem;
}
@media (max-width: 37.5em) {
  .operations__details {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 4rem;
    margin: 4rem;
  }
}
@media (max-width: 37.5em) {
  .operations__details {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 4rem;
    margin: 4rem;
  }
}
.operations__details--item {
  font-size: 1.9rem;
  line-height: 2.2rem;
  color: #000000;
  font-weight: 400;
  text-align: justify;
}
.operations__pic {
  background-color: #808080;
  width: 100%;
  padding: 4.5rem 0 2rem;
  margin: auto;
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.operations__pic .pic {
  width: 100%;
  max-width: 106rem;
}
.operations__pic .btn {
  background-color: #facf2f;
  height: 5.4rem;
  border-radius: 1.2rem;
  border: 0.5rem solid #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: #ffffff;
  margin: 6rem 0 0;
  padding: 0 1.6rem;
  cursor: pointer;
  transition: 0.5s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.operations__pic .btn:hover {
  background-color: #f0bf06;
}

.main-footer {
  background-color: #4a4a4a;
  width: 100%;
  position: relative;
  padding: 3.4rem 0;
}
.main-footer__container {
  width: 100%;
  max-width: 106rem;
  margin: auto;
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-footer__logo {
  width: 10rem;
}
.main-footer__links {
  display: flex;
  padding-top: 3.5rem;
  padding-bottom: 3rem;
}
.main-footer__links--item {
  font-size: 1.8rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 1;
  cursor: pointer;
  transition: 0.5s;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}
.main-footer__links--item:hover {
  opacity: 0.6;
}
.main-footer__links--item + .main-footer__links--item {
  margin-left: 3.6rem;
}
.main-footer__copy {
  font-size: 1.4rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 1;
}

.operations-header {
  width: 100%;
  height: 90rem;
  background-color: #ffffff;
  position: relative;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.operations-header__title {
  font-size: 3.8rem;
  line-height: 3.9rem;
  color: #4A4A4A;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 5;
}
@media (max-width: 37.5em) {
  .operations-header__title {
    top: 7rem;
  }
}
@media (max-width: 37.5em) {
  .operations-header__title {
    top: 7rem;
  }
}
.operations-header__desc {
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #000000;
  font-weight: 300;
  text-align: center;
  margin-top: 30px;
  max-width: 50rem;
  position: absolute;
  top: 9rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
@media (max-width: 37.5em) {
  .operations-header__desc {
    top: 12rem;
    max-width: 100%;
  }
}
@media (max-width: 37.5em) {
  .operations-header__desc {
    top: 12rem;
    max-width: 100%;
  }
}
.operations-header__menu {
  position: absolute;
  z-index: 100;
  top: 4rem;
  right: 7.5rem;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: 0.5s;
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .operations-header__menu {
    top: 3.2rem;
    right: 3.2rem;
  }
}
@media (max-width: 37.5em) {
  .operations-header__menu {
    top: 3.2rem;
    right: 3.2rem;
  }
}
.operations-header__menu img {
  width: 4.5rem;
  height: auto;
}
.operations-header__menu:hover {
  opacity: 0.6;
}

.bi-modal-solution {
  width: 100%;
  position: relative;
  background-color: #808080;
  padding-top: 5rem;
  padding-bottom: 9rem;
}
.bi-modal-solution__title {
  font-size: 3.8rem;
  line-height: 3.9rem;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  margin: auto;
  margin-bottom: 5rem;
}
@media (max-width: 37.5em) {
  .bi-modal-solution__title {
    padding: 0 4rem;
  }
}
@media (max-width: 37.5em) {
  .bi-modal-solution__title {
    padding: 0 4rem;
  }
}
.bi-modal-solution__pic {
  width: 100%;
  height: auto;
}

.description {
  width: 100%;
  position: relative;
}
.description__bg {
  width: 100%;
}
.description__container {
  background-color: #4a4a4a;
  width: 100%;
  height: 17rem;
  margin: auto;
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 37.5em) {
  .description__container {
    height: 25rem;
  }
}
@media (max-width: 37.5em) {
  .description__container {
    height: 25rem;
  }
}
.description__list {
  position: absolute;
  top: 7.5rem;
  width: 100%;
  max-width: 106rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
}
@media (max-width: 37.5em) {
  .description__list {
    transform: translateX(-50%) scale(0.9);
  }
}
@media (max-width: 37.5em) {
  .description__list {
    transform: translateX(-50%) scale(0.9);
  }
}
.description__list--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-basis: 1rem;
}
.description__list--item .num {
  width: 20.5rem;
  height: 20.5rem;
  border-radius: 11rem;
  background-color: #4a4a4a;
  border: 1.2rem solid #facf2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.description__list--item .num .value {
  font-size: 7rem;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
}
.description__list--item .num .label {
  font-size: 2rem;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
}
.description__list--item .title {
  font-size: 1.6rem;
  color: #000000;
  font-weight: 700;
  margin-top: 2rem;
  text-align: center;
}
.description__list--item .desc {
  font-size: 1.6rem;
  color: #000000;
  font-weight: 400;
  text-align: center;
}

.about-header {
  width: 100%;
  padding-top: 13rem;
  padding-bottom: 5rem;
  background-color: #4a4a4a;
  position: relative;
}
.about-header__container {
  width: 100%;
  max-width: 106rem;
  margin: auto;
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 37.5em) {
  .about-header__container {
    padding: 0 4rem;
  }
}
@media (max-width: 37.5em) {
  .about-header__container {
    padding: 0 4rem;
  }
}
.about-header__menu {
  position: absolute;
  top: 4rem;
  right: 7.5rem;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: 0.5s;
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .about-header__menu {
    top: 3.2rem;
    right: 3.2rem;
  }
}
@media (max-width: 37.5em) {
  .about-header__menu {
    top: 3.2rem;
    right: 3.2rem;
  }
}
.about-header__menu img {
  width: 4.5rem;
  height: auto;
}
.about-header__menu:hover {
  opacity: 0.6;
}
.about-header__title {
  font-size: 4rem;
  font-weight: 700;
  color: #ebebeb;
  text-align: center;
}
.about-header__desc {
  font-size: 1.8rem;
  font-weight: 300;
  color: #ebebeb;
  text-align: justify;
  margin-top: 4rem;
}

.about-content {
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-color: #facf2f;
  position: relative;
}
.about-content__container {
  width: 100%;
  max-width: 106rem;
  margin: auto;
  position: relative;
  z-index: 50;
  display: flex;
}
@media (max-width: 37.5em) {
  .about-content__container {
    padding: 4rem;
    flex-direction: column;
  }
}
@media (max-width: 37.5em) {
  .about-content__container {
    padding: 4rem;
    flex-direction: column;
  }
}
.about-content__col {
  flex-grow: 1;
  flex-basis: 1rem;
}
.about-content__col:first-child {
  padding-right: 4rem;
}
@media (max-width: 37.5em) {
  .about-content__col:first-child {
    padding-bottom: 4rem;
    padding-right: 0;
  }
}
@media (max-width: 37.5em) {
  .about-content__col:first-child {
    padding-bottom: 4rem;
    padding-right: 0;
  }
}
.about-content__col:last-child {
  padding-left: 4rem;
}
@media (max-width: 37.5em) {
  .about-content__col:last-child {
    padding-top: 4rem;
    padding-left: 0;
  }
}
@media (max-width: 37.5em) {
  .about-content__col:last-child {
    padding-top: 4rem;
    padding-left: 0;
  }
}
.about-content__col + .about-content__col {
  border-left: 0.2rem solid #000000;
}
@media (max-width: 37.5em) {
  .about-content__col + .about-content__col {
    border-top: 0.2rem solid #000000;
    border-left: none;
  }
}
@media (max-width: 37.5em) {
  .about-content__col + .about-content__col {
    border-top: 0.2rem solid #000000;
    border-left: none;
  }
}
.about-content__col .title {
  font-size: 1.8rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0.4rem;
}
.about-content__col .desc {
  font-size: 1.8rem;
  font-weight: 300;
  color: #000000;
  margin-bottom: 0.6rem;
  margin-left: 1.2rem;
}

.usecases-header {
  width: 100%;
  padding-top: 13rem;
  padding-bottom: 5rem;
  background-color: #4a4a4a;
  position: relative;
}
.usecases-header__container {
  width: 100%;
  max-width: 106rem;
  margin: auto;
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.usecases-header__menu {
  position: absolute;
  top: 4rem;
  right: 7.5rem;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: 0.5s;
  cursor: pointer;
}
.usecases-header__menu img {
  width: 4.5rem;
  height: auto;
}
.usecases-header__menu:hover {
  opacity: 0.6;
}
.usecases-header__title {
  font-size: 4rem;
  font-weight: 700;
  color: #ebebeb;
  text-align: center;
}

.usecases-content {
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-color: #808080;
  position: relative;
}
.usecases-content__container {
  width: 100%;
  max-width: 106rem;
  margin: auto;
  position: relative;
  z-index: 50;
}
.usecases-content__item {
  padding: 1.5rem;
  display: flex;
}
@media (max-width: 37.5em) {
  .usecases-content__item {
    padding: 4rem;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 37.5em) {
  .usecases-content__item {
    padding: 4rem;
    flex-direction: column;
    align-items: center;
  }
}
.usecases-content__item--img {
  width: 24rem;
  height: auto;
}
.usecases-content__item--title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #000000;
  padding-left: 2.4rem;
  width: 19rem;
}
@media (max-width: 37.5em) {
  .usecases-content__item--title {
    padding: 3.2rem 0;
  }
}
@media (max-width: 37.5em) {
  .usecases-content__item--title {
    padding: 3.2rem 0;
  }
}
.usecases-content__item--details {
  flex-grow: 1;
}
.usecases-content__item--desc {
  font-size: 1.8rem;
  font-weight: 300;
  color: #ffffff;
}
.usecases-content__item--desc + .usecases-content__item--desc {
  margin-top: 2rem;
}
.usecases-content__item + .usecases-content__item {
  border-top: 0.2rem solid #4a4a4a;
}

.blog-header {
  width: 100%;
  padding-top: 13rem;
  padding-bottom: 5rem;
  background-color: #4a4a4a;
  position: relative;
}
.blog-header__container {
  width: 100%;
  max-width: 106rem;
  margin: auto;
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blog-header__menu {
  position: absolute;
  top: 4rem;
  right: 7.5rem;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: 0.5s;
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .blog-header__menu {
    top: 3.2rem;
    right: 3.2rem;
  }
}
@media (max-width: 37.5em) {
  .blog-header__menu {
    top: 3.2rem;
    right: 3.2rem;
  }
}
.blog-header__menu img {
  width: 4.5rem;
  height: auto;
}
.blog-header__menu:hover {
  opacity: 0.6;
}
.blog-header__title {
  font-size: 4rem;
  font-weight: 700;
  color: #ebebeb;
  text-align: center;
}

.blog-content {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.6rem;
  background-color: #ffffff;
  position: relative;
}
.blog-content__container {
  width: 100%;
  max-width: 106rem;
  margin: auto;
  position: relative;
  z-index: 50;
}
@media (max-width: 37.5em) {
  .blog-content__container {
    padding: 2rem;
  }
}
@media (max-width: 37.5em) {
  .blog-content__container {
    padding: 2rem;
  }
}
.blog-content__item {
  background-color: #f4f3f3;
  padding: 1rem;
  
  border-radius: 1.2rem;
}

@media screen and (min-width: 37.5em) {
  .blog-content__item {
    display: flex;
  }
}

.blog-content__item--img {
  width: 25.6rem;
  height: 14rem;
  border: 0.6rem solid #ffffff;
  border-radius: 0.4rem;
  object-fit: cover;
}
@media (max-width: 37.5em) {
  .blog-content__item--img {
    height: auto;
  }
}
@media (max-width: 37.5em) {
  .blog-content__item--img {
    height: auto;
  }
}
.blog-content__item--body {
  flex-grow: 1;
  padding: 0 3.6rem;
  display: flex;
  flex-direction: column;
}
.blog-content__item--body .date {
  font-size: 1.8rem;
  font-weight: 300;
  color: #000000;
  align-self: flex-end;
}
.blog-content__item--body .title {
  font-size: 1.8rem;
  font-weight: 500;
  color: #000000;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.blog-content__item--body .desc {
  font-size: 1.8rem;
  font-weight: 300;
  color: #000000;
  margin-bottom: 0.6rem;
}
.blog-content__item--body .btn {
  font-size: 1.8rem;
  font-weight: 300;
  color: #000000;
  font-style: italic;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.5s;
  align-self: flex-end;
}
.blog-content__item--body .btn:hover {
  opacity: 0.6;
}
.blog-content__item + .blog-content__item {
  margin-top: 0.8rem;
}

.main-content {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
}